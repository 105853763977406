import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { GainsightInitialized } from '@src/localVariables';
import { FormInfo } from './useFormInfo';

const gdprCountryCodes = new Set([
  'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR',
  'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL',
  'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB', 'IS',
  'NO', 'LI'
]);

const useGainsightInitialization = (formInfo: FormInfo) => {
  const gainsightInitialized = useReactiveVar(GainsightInitialized);

  useEffect(() => {
    if (!gainsightInitialized && formInfo?.form?.document?.organization) {
      try {
        const organization = formInfo.form.document.organization;
        const countryCode = organization.countryCode?.toUpperCase().trim() ?? '';
        const isGDPRCountry = gdprCountryCodes.has(countryCode);

        const isProduction = process.env.NODE_ENV === 'production';

        // Create unique hashed user ID
        let userId = `PC${isProduction ? '' : '-PREPROD'}`; // Add PREPROD if not in production
        
        // If the user is in a GDPR country, append the GDPR tag
        if (isGDPRCountry) {
          userId += `-ANONYMOUS-GDPR-${Math.floor(Math.random() * (100000000 - 1) + 1).toString()}`;
        } else {
          userId += `-ANONYMOUS-${Math.floor(Math.random() * (100000000 - 1) + 1).toString()}`;
        }

        // Initialize Gainsight.
        //@ts-expect-error - aptrinsic is Gainsight.
        aptrinsic("identify",
          {
            // User Fields
            "id": userId, // Unique and possibly hashed ID with GDPR check and preprod tag if applicable.
            "role": "Responder", // Role of the user's profile.
          },
          {
            // Account Fields
            "id": `PC-${organization.id}`, // Organization ID.
            "name": organization.name, // Organization name.
          }
        );
        
        GainsightInitialized(true); // Mark Gainsight as initialized
        console.log('Gainsight initialized');

      } catch (error) {
        console.error(error);
        GainsightInitialized(true); // Mark it initialized even in case of failure to prevent retries.
      }
    }
  }, [formInfo, gainsightInitialized]);
};

export default useGainsightInitialization;
